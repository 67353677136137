import { GraphQLClient } from "graphql-request"
import _ from "lodash"

let tempId = 0

const finEndpoint = "https://api.stella.sanuker.com/v2.0/"
const finToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJBUEkiLCJhcHAiOiI1Y2NmZmQwM2MwNmY4YmNkZWVmMjhjMjciLCJhY2wiOlsiZGF0YXNvdXJjZTphcGkiXSwianRpIjoiNTJiMmVlNGEtNWQ5MS01Y2QwLTgwZGEtNmQzY2NhNDFjZGNkIiwiaXNzIjoiaW50ZXJuYWwiLCJpYXQiOjE1NzA1MzA3MDk3Mjl9.DPyQOB9gmKa3atqF4fANKATS3813gHaioLmLTo2wLgA"

const client = new GraphQLClient(finEndpoint, {
  headers: {
    authorization: `Bearer ${finToken}`,
  },
})

export async function fetchDataFromDataSource({
  dataSourceId,
  filter,
}) {
  const query = /* GraphQL */ `
    query fetchDataFromDataSource(
      $dataSourceId: ID,
      $filter: JSON,
    ) {
      apiViewer {
        fetchDataSourceData(
          dataSourceId: $dataSourceId,
          filter: $filter,
        )
      }
    }
  `
  const variables = {
    dataSourceId,
    filter
  }
  const result = await client.request(query, variables)
  const data = _.get(result, "apiViewer.fetchDataSourceData")
  return data
}

export async function upsertDataToDataSource({
  collectionName,
  data,
}) {
  const mutation = /* GraphQL */ `
    mutation upsertDataToDataSource(
      $input: UpsertDataSourceDocsInput!,
    ) {
      upsertDataSourceDocs(
        input: $input 
      ) {
        result
      }
    }
  `
  const variables = {
    input: {
      collectionName,
      data,
      clientMutationId: tempId++
    }
  }
  const result = await client.request(mutation, variables)
  return _.get(result, "upsertDataSourceDocs.result")
}


export async function findAndModifyDataSource({
  collectionName,
  filter,
  patch,
}) {
  const mutation = /* GraphQL */ `
    mutation findAndModifyDataSource(
      $input: FindAndModifyDataSourceDocInput!,
    ) {
      findAndModifyDataSourceDoc(
        input: $input 
      ) {
        result
      }
    }
  `
  const variables = {
    input: {
      collectionName,
      filter,
      patch,
      clientMutationId: tempId++
    }
  }
  const result = await client.request(mutation, variables)
  return _.get(result, "findAndModifyDataSourceDoc.result")
}
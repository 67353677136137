/* eslint-disable no-undef */
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import styled, { css } from "styled-components"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus, faChevronUp, faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { fetchDataFromDataSource, upsertDataToDataSource, findAndModifyDataSource } from "../../data/api"

const Zendesk = styled.div`
  color: #707070;

  .wrapper {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 8px 16px;
    margin: 16px;
  }
`

const Button = styled.div`
  border: 1px solid #f9f9f9;
  background-color: #0063f0;
  color: #ffffff;
  margin: 8px 0;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  max-width: 100px;
  font-size: 0.8rem;
`

const Input  = styled.div`
  .label {
    font-size: 0.8rem;
    padding-bottom: 4px;
  }
  .text-input {
    padding: 8px 0;
    width: calc(100% - 4px);
    color: #505050;
    font-size: 1rem;
  }
`

class Home extends Component {
  constructor(props) {
    super (props)
    this.state= {
      accessToken: "",
      return_url: "",
      name: "",
      subdomain: "",
      locale: "",
      metadata: "",
      state: "",
      zendesk_access_token: "",
      instance_push_id: ""
    }
  }

  componentDidMount() {
    if (_.get(this.props, "match.location.query.return_url")) {
      this.setState({
        return_url: this.props.match.location.query.return_url,
        name: this.props.match.location.query.name,
        subdomain: this.props.match.location.query.subdomain,
        locale: this.props.match.location.query.locale,
        metadate: this.props.match.location.query.metadate,
        state: this.props.match.location.query.state,
        instance_push_id: this.props.match.location.query.instance_push_id,
        zendesk_access_token: this.props.match.location.query.zendesk_access_token
      })
    }
  }

  render () {
  
    return (
      <Zendesk className="zendesk-container">
        <div className="wrapper">
          <Input className="input-container">
            <div className="label">Stella Access Token</div>
            <input
              className="text-input"
              type="text"
              value={this.state.accessToken}
              onChange={(e) => {
                this.setState({
                  accessToken: e.target.value
                })
              }}
            />
          </Input>
          <Button 
            className="send-button"
          >
            Send
          </Button>
        </div>
      </Zendesk>
    )
  }
}

export default Home
